<template>
  <div class="User">
    <transition name="fade" mode="out-in">
      <alert v-if="alertOpen" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div class="panel">
      <div class="Grid-row">
        <div class="Grid-column-6">
          <button class="button button-success" @click="expireAllSuperusers">
            Expire All
          </button>
        </div>
      </div>
      <div class="Grid-row">
        <div class="Grid-column-12">
          <ul class="Expiration__list">
            <li class="Expiration__list-row expiration__list-row--header">
              <span class="Expiration__list-item">Name</span>
              <span class="Expiration__list-item">Username</span>
              <span class="Expiration__list-item">Account Type</span>
              <span class="Expiration__list-item">Expiration Time</span>
              <span class="Expiration__list-item">Actions</span>
            </li>
            <li
              v-for="(user, index) in superusers"
              :key="index"
              class="Expiration__list-row"
            >
              <span class="Expiration__list-item">{{ user.name }}</span>
              <span class="Expiration__list-item">{{ user.username }}</span>
              <span class="Expiration__list-item">{{
                user.superuser.accountType
              }}</span>
              <span class="Expiration__list-item">
                <datepicker
                  v-model="expirationTimes[user.userId]"
                  @selected="dateUpdated(user.userId, $event)"
                ></datepicker>
              </span>
              <span class="Expiration__list-item">
                <button
                  class="button button-success"
                  @click="expireSuperuser(user.userId)"
                >
                  Change Expiration
                </button>
              </span>
            </li>
            <infinite-loading
              ref="infiniteLoading"
              @if="superusersNotLoaded"
              @infinite="infiniteHandler"
            >
              <span slot="no-more">All superusers loaded.</span>
            </infinite-loading>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';
import Datepicker from 'vuejs-datepicker';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Expiration',
  components: {
    Alert,
    Datepicker,
    InfiniteLoading,
  },
  data() {
    return {
      error: false,
      alertOpen: false,
      alertContent: null,
    };
  },
  computed: {
    superusers: {
      get() {
        return this.$store.getters.superusers;
      },
    },
    expirationTimes: {
      get() {
        return this.$store.getters.expirationTimes;
      },
    },
    superusersNotLoaded: {
      get() {
        return this.$store.getters.superusersNotLoaded;
      },
    },
  },
  methods: {
    closeAlert() {
      this.alertOpen = false;
    },
    dateUpdated(userId, $event) {
      this.$store.dispatch('updateExpirationDate', { userId, $event });
    },
    expireSuperuser(userId) {
      this.$store
        .dispatch('expireSuperuser', { userId })
        .then(() => {
          this.alertContent = 'Superuser expiration date changed.';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were errors processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    expireAllSuperusers() {
      this.$store
        .dispatch('expireAllSuperusers')
        .then(() => {
          this.alertContent = 'All Superusers expired.';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were errors processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    infiniteHandler(state) {
      this.$store.dispatch('getSuperusers').then(() => {
        if (this.superusersNotLoaded) {
          state.loaded();
        } else {
          state.complete();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
@import '@/assets/stylesheets/components/superusers/Expiration.scss';
</style>
